import {
  CUTOFF_TIME,
  DELIVERY_DAYS_WINDOW,
  DELIVERY_OPTIONS_CARRIER_SETTINGS_DISABLED,
  DROP_OFF_DELAY,
  MAP_MAX_ZOOM,
} from '@/services/delivery-options/constants';
import { getApiBaseUrl } from '@/services/delivery-options/getApiBaseUrl';
import { getDeliveryOptionsPlatform } from '@/services/delivery-options/getDeliveryOptionsPlatform';

export const deliveryOptionsConfig = {
  allowRetry: false,
  apiBaseUrl: getApiBaseUrl(),
  cutoffTime: CUTOFF_TIME,
  deliveryDaysWindow: DELIVERY_DAYS_WINDOW,
  dropOffDelay: DROP_OFF_DELAY,
  hidePrices: true,
  mondayCutoffTime: CUTOFF_TIME,
  packageType: 'package',
  pickupLocationsMapTileLayerData: {
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    attribution: '<a href="https://carto.com/attributions">CARTO</a>',
    maxZoom: MAP_MAX_ZOOM,
  },
  platform: getDeliveryOptionsPlatform(),
  saturdayCutoffTime: CUTOFF_TIME,
  ...DELIVERY_OPTIONS_CARRIER_SETTINGS_DISABLED,
};
