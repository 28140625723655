import {
  DELIVERY_OPTIONS_CARRIER_SETTINGS,
  DELIVERY_OPTIONS_CARRIER_SETTINGS_NO_PICKUP,
} from '@/services/delivery-options/constants';
import { PACKAGE_TYPE_PACKAGE } from '@/helpers/packageTypeConfig';

/**
 * @param {{name: String, id: Number}} packageType
 * @param {{name: String, id: Number}} carrier
 * @returns {Object}
 */
export function getDeliveryOptionsCarrierSettings(packageType, carrier) {
  const canHavePickup = PACKAGE_TYPE_PACKAGE === packageType.name;

  return {
    [carrier.name]: canHavePickup
      ? DELIVERY_OPTIONS_CARRIER_SETTINGS
      : DELIVERY_OPTIONS_CARRIER_SETTINGS_NO_PICKUP,
  };
}
