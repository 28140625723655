<template>
  <Card
    key="social-shipping__delivery-options"
    title="social_shipping.delivery_options_title">
    <template v-if="shouldShowDeliveryOptions">
      <SmoothReflow>
        <DeliveryOptions
          :config="deliveryOptionsConfig"
          @delivery-options:update="onDeliveryOptionsUpdate"
          @delivery-options:updated="onDeliveryOptionsUpdated" />
        <Alert
          v-if="fitsInMailbox"
          v-t="'social_shipping.delivery_options_mailbox'"
          variant="success"
          class="m-0 mt-2" />
      </SmoothReflow>
    </template>

    <p
      v-else-if="!loading"
      v-t="'social_shipping.delivery_options_description'" />
  </Card>
</template>

<script>
import { PACKAGE_TYPE_DIGITAL_STAMP, PACKAGE_TYPE_MAILBOX, getPackageType } from '@/helpers/packageTypeConfig';
import { STORE_DELIVERY_OPTIONS, STORE_MODULE_SOCIAL_SHIPPING } from '@/store/modules/social-shipping/constants';
import { mapGetters, mapState } from 'vuex';
import { ADDRESS_FINDER_GROUP } from '@/data/forms/addressFinder';
import Alert from '@/components/Alert';
import { COUNTRIES_WITH_ADDRESS_FINDER } from '@/data/forms/countryData';
import Card from '@/components/Card';
import { deliveryOptionsConfig } from '@/services/delivery-options/deliveryOptionsConfig';
import { get } from 'lodash-es/object';
import { getCarrier } from '@/helpers/getCarrier';
import { getDeliveryOptionsCarrierSettings } from '@/services/delivery-options/getDeliveryOptionsCarrierSettings';
import { getDeliveryOptionsStrings } from '@/services/delivery-options/getDeliveryOptionsStrings';
import isEqual from 'lodash-es/isEqual';
import { socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';

export default {
  name: 'SocialShippingDeliveryOptions',
  components: {
    Alert,
    DeliveryOptions: () => import('@/components/DeliveryOptions'),
    Card,
  },

  data() {
    return {
      deliveryOptionsConfig: {},
      loading: false,
    };
  },

  computed: {
    ...mapState('socialShipping', ['socialShippingLink']),
    ...mapGetters('socialShipping', ['recipient']),

    /**
     * Decide if the delivery options should be shown by checking if all fields we depend on are filled in.
     *
     * @returns {Boolean}
     */
    isReady() {
      if (!this.recipient.isValid) {
        return false;
      }

      return Object
        .values(this.addressFields)
        .every((field) => Boolean(get(this.recipient, field, null)));
    },

    /**
     * @returns {Boolean}
     */
    shouldShowDeliveryOptions() {
      return this.isReady && !this.loading;
    },

    /**
     * @returns {{name: String, id: Number}}
     */
    packageType() {
      return getPackageType(this.socialShippingLink?.shipment_options.options.package_type);
    },

    /**
     * Returns whether the created shipment will fit in a mailbox or not.
     *
     * @returns {Boolean}
     */
    fitsInMailbox() {
      return [PACKAGE_TYPE_MAILBOX, PACKAGE_TYPE_DIGITAL_STAMP].includes(this.packageType.name);
    },

    /**
     *
     */
    addressFields() {
      const usesAddressFinder = COUNTRIES_WITH_ADDRESS_FINDER.includes(this.recipient.cc);

      return {
        cc: 'cc',
        city: 'city',
        street: 'street',
        postalCode: usesAddressFinder ? `${ADDRESS_FINDER_GROUP}.postal_code` : 'postal_code',
        ... usesAddressFinder ? { number: `${ADDRESS_FINDER_GROUP}.number` } : {},
      };
    },
  },

  watch: {
    '$i18n.locale'() {
      this.updateDeliveryOptionsConfig();
    },

    recipient: {
      immediate: true,
      deep: true,

      /**
       * Trigger an update of the delivery options config when specific keys in recipient change.
       *
       * @param {Object} newRecipient
       * @param {Object} oldRecipient
       *
       */
      handler(newRecipient, oldRecipient) {
        const changedParts = Object
          .values(this.addressFields)
          .some((part) => get(newRecipient, part, null) !== get(oldRecipient, part, null));

        if (changedParts) {
          this.updateDeliveryOptionsConfig();
        }
      },
    },
  },

  mounted() {
    /**
     * Attach loading prop to the pending state of the formBus to be able to hide the delivery options while a new
     *  address is being validated.
     */
    socialShippingFormBus.$on('find_address:start', () => {
      this.loading = true;
    });
    socialShippingFormBus.$on('find_address:end', () => {
      this.loading = false;
    });
  },

  beforeDestroy() {
    socialShippingFormBus.$off('find_address:start');
    socialShippingFormBus.$off('find_address:end');
  },

  methods: {
    /**
     * Update the delivery options configuration. Triggered by various watchers.
     */
    updateDeliveryOptionsConfig() {
      const shipmentOptions = this.socialShippingLink?.shipment_options;
      const chosenCarrier = getCarrier(shipmentOptions?.carrier);

      // Create an address object with the values from this.recipient.
      const address = Object
        .entries(this.addressFields)
        .reduce((acc, [field, value]) => ({
          ...acc,
          [field]: get(this.recipient, value, null),
        }), {});

      const config = {
        strings: getDeliveryOptionsStrings(this),
        address,
        config: {
          ...deliveryOptionsConfig,
          showPrices: false,
          packageType: this.packageType.name,
          locale: this.$i18n.locale.replace('_', '-'),
          carrierSettings: getDeliveryOptionsCarrierSettings(this.packageType, chosenCarrier),
        },
      };

      // We can't use computed/watchers because object equality would not be handled properly.
      if (!isEqual(config, this.deliveryOptionsConfig)) {
        this.deliveryOptionsConfig = config;
      }
    },

    onDeliveryOptionsUpdate() {
      this.$store.commit(`${STORE_MODULE_SOCIAL_SHIPPING}/${STORE_DELIVERY_OPTIONS}`);
    },

    onDeliveryOptionsUpdated(data) {
      this.$store.commit(`${STORE_MODULE_SOCIAL_SHIPPING}/${STORE_DELIVERY_OPTIONS}`, data);
    },
  },
};
</script>
