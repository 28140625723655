/* eslint-disable no-magic-numbers */
import { getValueFromMap } from '@/helpers/getValueFromMap';

export const PACKAGE_TYPE_PACKAGE = 'package';
export const PACKAGE_TYPE_MAILBOX = 'mailbox';
export const PACKAGE_TYPE_DIGITAL_STAMP = 'digital_stamp';
export const PACKAGE_TYPE_LETTER = 'letter';

const packageTypeMapping = [
  {
    id: 1,
    name: PACKAGE_TYPE_PACKAGE,
  },
  {
    id: 2,
    name: PACKAGE_TYPE_MAILBOX,
  },
  {
    id: 3,
    name: PACKAGE_TYPE_LETTER,
  },
  {
    id: 4,
    name: PACKAGE_TYPE_DIGITAL_STAMP,
  },
];

/**
 * Get a package type from given id or name.
 *
 * @param {String|Number} packageType
 * @returns {Object}
 */
export function getPackageType(packageType) {
  return getValueFromMap(packageType, packageTypeMapping);
}
