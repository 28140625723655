import { platform } from '@/helpers/platform';

/**
 * Returns platform and changes "sendmyparcel" to "belgie".
 *
 * @returns {String}
 */
export function getDeliveryOptionsPlatform() {
  let deliveryOptionsPlatform = platform;

  if (platform === 'sendmyparcel') {
    deliveryOptionsPlatform = 'belgie';
  }

  return deliveryOptionsPlatform;
}
