// Events
export const EVENT_RENDER_DELIVERY_OPTIONS = 'myparcel_render_delivery_options';
export const EVENT_UPDATED_DELIVERY_OPTIONS = 'myparcel_updated_delivery_options';
export const EVENT_UPDATE_CONFIG = 'myparcel_update_config';
export const EVENT_UPDATE_DELIVERY_OPTIONS = 'myparcel_update_delivery_options';

// Settings
export const CUTOFF_TIME = '16:00';
export const MAP_MAX_ZOOM = 19;
export const DELIVERY_DAYS_WINDOW = 4;
export const DROP_OFF_DELAY = 0;

export const DELIVERY_OPTIONS_CARRIER_SETTINGS_DISABLED = {
  allowEveningDelivery: false,
  allowMondayDelivery: false,
  allowMorningDelivery: false,
  allowOnlyRecipient: false,
  allowPickupLocations: false,
  allowSaturdayDelivery: false,
  allowSignature: false,
  allowStandardDelivery: false,
};

export const DELIVERY_OPTIONS_CARRIER_SETTINGS = {
  ...DELIVERY_OPTIONS_CARRIER_SETTINGS_DISABLED,
  allowPickupLocations: true,
  allowStandardDelivery: true,
};

// Todo: https://github.com/myparcelnl/delivery-options/issues/63
export const DELIVERY_OPTIONS_CARRIER_SETTINGS_NO_PICKUP = {
  ...DELIVERY_OPTIONS_CARRIER_SETTINGS_DISABLED,
  allowStandardDelivery: true,
};
