import config from '@/helpers/config';

/**
 * If the API URL doesn't start with a protocol, like on staging, prepend the protocol. This is necessary because the
 *  delivery options puts it in new URL() which won't work with URLs without a protocol.
 *
 * @returns {String}
 */
export function getApiBaseUrl() {
  let url = config.apiURL;

  if (!url.startsWith('http')) {
    url = window.location.protocol + url;
  }

  return url;
}
