/**
 * Returns translations of the strings needed in the delivery options.
 *
 * @param {Object} instance - Vue instance.
 * @param {Function} instance.$t
 *
 * @returns {Object}
 */
export const getDeliveryOptionsStrings = (instance) => ({
  addressNotFound: instance.$t('delivery_options.address_not_found'),
  closed: instance.$t('delivery_options.closed'),
  deliveryTitle: instance.$t('delivery_options.delivery_title'),
  from: instance.$t('delivery_options.from'),
  loadMore: instance.$t('delivery_options.load_more'),
  onlyRecipientTitle: instance.$t('delivery_options.only_recipient_title'),
  pickupLocationsListButton: instance.$t('delivery_options.pickup_locations_list_button'),
  pickupLocationsMapButton: instance.$t('delivery_options.pickup_locations_map_button'),
  pickupTitle: instance.$t('delivery_options.pickup_title'),
  signatureTitle: instance.$t('delivery_options.signature_title'),
  wrongNumberPostalCode: instance.$t('delivery_options.wrong_number_postal_code'),
});
